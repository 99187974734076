import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { typedEnv, buildEnv } from "./environments/environment";

const boot = () => import("./boot").catch(renderError);

const baseRender = (node: string) => {
  const root = document.getElementById("root");
  if (!root) {
    return;
  }
  root.innerHTML = node;
};

const renderError = (err: any) => {
  baseRender(
    `<div>
      <h2>Error:</h2>
      <div>${err.message}</div>
    </div>`
  );
};

baseRender("<i>Loading...</i>");

if (typedEnv.REACT_APP_ENV_NAME !== "dynamic") {
  boot();
} else {
  // path has to be used from variable
  // so compiler don't verify module existance
  const path = "/dynamic-env.js";
  import(
    /* webpackIgnore: true */
    path
  )
    .then((mod) => {
      mod.mutate({
        env: typedEnv,
        buildEnv,
      });
      boot();
    })
    .catch(renderError);
}
