import { v } from "overshom-valid";

class TypedEnv extends v.class({
  REACT_APP_ENV_NAME: v.String(),
  REACT_APP_API_URL: v.String(),
  REACT_APP_MUI_LICENSE_KEY: v.String(),
  REACT_APP_VERSION: v.String(),
  REACT_APP_API_DOCS_URL: v.String(),
  REACT_APP_EXCLUDE_ROUTES_LIST: v.Array(v.String()),
}) {}

export const buildEnv = (obj: any) => new TypedEnv(obj);

const IS_NOT_DEV_ENV = process.env.REACT_APP_ENV_NAME !== "development";

export const typedEnv = buildEnv({
  ...process.env,
  REACT_APP_API_URL: IS_NOT_DEV_ENV
    ? `${window.location.protocol}//${window.location.host}/api/v1`
    : process.env.REACT_APP_API_URL,
  REACT_APP_EXCLUDE_ROUTES_LIST: process.env.REACT_APP_EXCLUDE_ROUTES_LIST?.split(",") || [],
});
